<template>
  <div style="background-color: #fafafa">
    <div
        id="container"
        style="display: none"
    ></div>
    <GridList title="预约信息" port-name="osp.car.VehicleReserve.getVehicleReserves"
              :request-data="{'visitor.id': visitorID,
              'sign': 'sign',
                'typeDic.code': 'VISITOR_CAR_TYPE_CHEMICAL_TRUCK',
                'statusDic.code': 'VISITOR_RESERVE_STATUS_PASS',
                'processStatus.code': 'PROCESS_STATUS_RESERVE',
              }"
              :dataList="dataList" style="margin: 4.26667vw;" ref="grid">
      <div class="item"
           v-for="item in dataList"
           :key="item.id">
        <van-image class="image" round :src="require('../../assets/img/访.png')"/>
        <div class="content">
          <div class="title fs16">{{ item['carNum'] }}</div>
          <div class="tags fs12"><span style="color: #129d67">{{ item['typeDic.name'] }}</span></div>
          <div class="time fs12">{{ item['planDate'] }}</div>
        </div>
        <div class="fs12">
          <van-button round block type="primary" style="height: 9.6vw" @click="onClick(item.id)">
            签到
          </van-button>
        </div>
      </div>
    </GridList>
  </div>

</template>

<script>
import ListCard from "../../components/ListCard";
import _ from "lodash";
import {Dialog, Toast} from "vant";
import GridList from "../../components/GridList";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  components: {GridList},
  name: "SignIn",
  data() {
    return {
      visitorID: '',
      dataList: [],
      location: {},
      geolocation: null,
      planDate: new Date().pattern('yyyy-MM-dd').toString()
    };
  },
  methods: {
    initMap: function () {
      AMapLoader.load({
        key: "f7c8f1754dec8e4a9b202b27f97d133a", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.MapType",
          "AMap.Geolocation",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "1.3.2", // Loca 版本，缺省 1.3.2
        },
      })
          .then((AMap) => {
            var map = new AMap.Map("container", {
              zoom: 11, //级别
              center: [119.619397, 32.168554],//中心点坐标
            });
            map.addControl(new AMap.ToolBar());
            // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
            map.addControl(new AMap.Scale());


            this.geolocation = new AMap.Geolocation({
              // 是否使用高精度定位，默认：true
              enableHighAccuracy: true,
              // 设置定位超时时间，默认：无穷大
              timeout: 10000,
              // 定位按钮的停靠位置的偏移量
              offset: [10, 20],
              //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
              zoomToAccuracy: true,
              //  定位按钮的排放位置,  RB表示右下
              position: 'RB'
            })


            // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
            map.addControl(this.geolocation);

            var marker = new AMap.Marker({
              position: [119.619397, 32.168554], //位置
              title: '镇江江南化工',
              label: {
                content: "<div style='color: red' '>镇江江南化工</div>", //设置文本标注内容
              }
            });
            map.add(marker); //添加到地图
          })

          .catch((e) => {
            console.log(e);
          });
    },

    onClick: async function (id) {
      let data = null;
      var me = this;
      if (!window.Capacitor) {
        if (this.geolocation == null) {
          Toast('定位中');
          return;
        }
        this.geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            var position = result.position;
            data = {
              latitude: position.lat,
              longitude: position.lng
            }
            me.location = {
              latitude: data.latitude,
              longitude: data.longitude
            }
            if (_.isEmpty(data) || _.isEmpty(me.location)) {
              Toast('定位失败，请重试');
              return;
            }
            me.$dialog.confirm({
              title: '',
              message: '是否进行签到？',
            })
                .then(() => {
                  var option = {
                    portName: 'osp.visitor.api.PartApi.signIn',
                    data: {id: id, location: me.location},
                    needLoading: false,
                    successCallback: () => {
                      me.$notify({type: 'success', message: '签到成功'})
                      me.$refs.grid.onRefresh();
                    }
                  }
                  me.$sapi.callPort(option);
                })
                .catch(() => {

                });
          } else {
            console.log(JSON.stringify(result));
          }
        });
      } else {
        data = await Capacitor.Plugins.AMapLocation.getCurrentLocation();
        this.location = {
          latitude: data.latitude,
          longitude: data.longitude
        }
        if (_.isEmpty(data) || _.isEmpty(this.location)) {
          Toast('定位失败，请重试');
          return;
        }
        this.$dialog.confirm({
          title: '',
          message: '是否进行签到？',
        })
            .then(() => {
              var option = {
                portName: 'osp.visitor.api.PartApi.signIn',
                data: {id: id, location: this.location},
                needLoading: false,
                successCallback: () => {
                  this.$notify({type: 'success', message: '签到成功'})
                  this.$refs.grid.onRefresh();
                }
              }
              this.$sapi.callPort(option);
            })
            .catch(() => {

            });
      }
    },
    async startLocation() {
      try {
        if (!window.Capacitor) return;
        await Capacitor.Plugins.AMapLocation.start();
        let data = await Capacitor.Plugins.AMapLocation.getCurrentLocation();
        this.location = {
          latitude: data.latitude,
          longitude: data.longitude
        }
      } catch (error) {
        alert(error.message);
      }
    },
    async stopLocation() {
      try {
        if (!window.Capacitor) return;
        await Capacitor.Plugins.AMapLocation.stop();
        this.positioning = false;
      } catch (error) {
        alert(error.message);
      }
    },
  },
  created: function () {
    var visitor = this.$sapi.storage.getItemJson('visitor');
    this.visitorID = visitor.id;
    this.initMap();
    this.startLocation();
  },
  mounted() {
    this.$refs.grid.onRefresh();
  },
  beforeUnmount() {
    this.stopLocation();
  }
}

</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.item > .title {
}

.item .status_green {
  color: #519874;
}

.item .status_red {
  color: #f83817;
}

.item > .content {
  flex: 1;
}

.item .time {
  color: #666;
}
</style>